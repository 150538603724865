// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("/tmp/build_4b6239ac/node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("/tmp/build_4b6239ac/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-admin-amr-js": () => import("/tmp/build_4b6239ac/src/pages/admin/amr.js" /* webpackChunkName: "component---src-pages-admin-amr-js" */),
  "component---src-pages-admin-batch-upload-js": () => import("/tmp/build_4b6239ac/src/pages/admin/batch-upload.js" /* webpackChunkName: "component---src-pages-admin-batch-upload-js" */),
  "component---src-pages-admin-batch-upload-preview-js": () => import("/tmp/build_4b6239ac/src/pages/admin/batch-upload/preview.js" /* webpackChunkName: "component---src-pages-admin-batch-upload-preview-js" */),
  "component---src-pages-admin-consultations-index-js": () => import("/tmp/build_4b6239ac/src/pages/admin/consultations/index.js" /* webpackChunkName: "component---src-pages-admin-consultations-index-js" */),
  "component---src-pages-admin-consultations-view-js": () => import("/tmp/build_4b6239ac/src/pages/admin/consultations/view.js" /* webpackChunkName: "component---src-pages-admin-consultations-view-js" */),
  "component---src-pages-admin-dashboard-js": () => import("/tmp/build_4b6239ac/src/pages/admin/dashboard.js" /* webpackChunkName: "component---src-pages-admin-dashboard-js" */),
  "component---src-pages-admin-eow-js": () => import("/tmp/build_4b6239ac/src/pages/admin/eow.js" /* webpackChunkName: "component---src-pages-admin-eow-js" */),
  "component---src-pages-admin-incident-reports-js": () => import("/tmp/build_4b6239ac/src/pages/admin/incident-reports.js" /* webpackChunkName: "component---src-pages-admin-incident-reports-js" */),
  "component---src-pages-admin-incident-reports-add-js": () => import("/tmp/build_4b6239ac/src/pages/admin/incident-reports/add.js" /* webpackChunkName: "component---src-pages-admin-incident-reports-add-js" */),
  "component---src-pages-admin-incident-reports-view-js": () => import("/tmp/build_4b6239ac/src/pages/admin/incident-reports/view.js" /* webpackChunkName: "component---src-pages-admin-incident-reports-view-js" */),
  "component---src-pages-admin-inventory-index-js": () => import("/tmp/build_4b6239ac/src/pages/admin/inventory/index.js" /* webpackChunkName: "component---src-pages-admin-inventory-index-js" */),
  "component---src-pages-admin-medcerts-index-js": () => import("/tmp/build_4b6239ac/src/pages/admin/medcerts/index.js" /* webpackChunkName: "component---src-pages-admin-medcerts-index-js" */),
  "component---src-pages-admin-medcerts-view-js": () => import("/tmp/build_4b6239ac/src/pages/admin/medcerts/view.js" /* webpackChunkName: "component---src-pages-admin-medcerts-view-js" */),
  "component---src-pages-consultations-js": () => import("/tmp/build_4b6239ac/src/pages/consultations.js" /* webpackChunkName: "component---src-pages-consultations-js" */),
  "component---src-pages-consultations-add-js": () => import("/tmp/build_4b6239ac/src/pages/consultations/add.js" /* webpackChunkName: "component---src-pages-consultations-add-js" */),
  "component---src-pages-consultations-edit-js": () => import("/tmp/build_4b6239ac/src/pages/consultations/edit.js" /* webpackChunkName: "component---src-pages-consultations-edit-js" */),
  "component---src-pages-consultations-view-js": () => import("/tmp/build_4b6239ac/src/pages/consultations/view.js" /* webpackChunkName: "component---src-pages-consultations-view-js" */),
  "component---src-pages-index-js": () => import("/tmp/build_4b6239ac/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-inventory-js": () => import("/tmp/build_4b6239ac/src/pages/inventory.js" /* webpackChunkName: "component---src-pages-inventory-js" */),
  "component---src-pages-inventory-view-js": () => import("/tmp/build_4b6239ac/src/pages/inventory/view.js" /* webpackChunkName: "component---src-pages-inventory-view-js" */),
  "component---src-pages-login-js": () => import("/tmp/build_4b6239ac/src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-medcerts-add-js": () => import("/tmp/build_4b6239ac/src/pages/medcerts/add.js" /* webpackChunkName: "component---src-pages-medcerts-add-js" */),
  "component---src-pages-medcerts-index-js": () => import("/tmp/build_4b6239ac/src/pages/medcerts/index.js" /* webpackChunkName: "component---src-pages-medcerts-index-js" */),
  "component---src-pages-medcerts-view-js": () => import("/tmp/build_4b6239ac/src/pages/medcerts/view.js" /* webpackChunkName: "component---src-pages-medcerts-view-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/tmp/build_4b6239ac/.cache/data.json")

