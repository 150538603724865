import axios from "axios";
import { camelizeKeys, decamelizeKeys } from "humps";

const api = axios.create({
  baseURL: `${process.env.GATSBY_API_URL}`,
});

api.interceptors.request.use(
  function(config) {
    if (config.data instanceof FormData) {
      config.method = "post";
      config.data.append("_method", "post");
    } else config.data = decamelizeKeys(config.data);
    config.headers = {
      ...config.headers,
      Authorization: sessionStorage.getItem("loginToken"),
      "x-remote-user": sessionStorage.getItem("employeeId"),
    };
    return config;
  },
  function(error) {
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  function(response) {
    return camelizeKeys(response);
  },
  function(error) {
    // Do something with response error
    return Promise.reject(error);
  }
);

export default api;
