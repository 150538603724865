import React, { useEffect } from "react";
import classNames from "classnames";

import styles from "./templates.module.scss";

const Toast = ({ message, type, timeout, unmount }) => {
  useEffect(() => {
    setTimeout(() => {
      if (unmount) unmount();
    }, timeout);
  }, []);

  return (
    <div className={classNames(`${type}`, styles.toast, "notification level")}>
      {message}
    </div>
  );
};

export default Toast;
