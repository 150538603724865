import React, { Component } from "react";

import moment from "moment";
import api from "../services/api";
import Toast from "../components/Templates/Toast";
import Modal from "../components/Templates/Modal";

const defaultState = {
  selectEmployee: () => {},
  employeeStatus: "isSearching",
  setEmployeeStatus: () => {},
  modalIsOpen: false,
  userDetails: {},
  employeeDetails: {},
  siteDetails: {},
  activeConsultation: {
    doctor: {},
    clinic: { site: {} },
    created: "",
    inventoryLogs: [],
    chiefComplaint: "",
  },
};

const AppContext = React.createContext(defaultState);

class AppProvider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isToastActive: false,
      message: "",
      toastType: "",
      toastTimeout: null,
      selectedEmployee: {},
      employeeStatus: "isSearching",
      modalIsOpen: false,
      activeModal: "",
      modal: {
        isOpen: false,
        children: null,
        color: null,
        heading: "",
      },
      userDetails: {},
      employeeDetails: {},
      siteDetails: {},
      activeConsultation: {
        doctor: {},
        clinic: { site: {} },
        created: "",
        inventoryLogs: [],
        chiefComplaint: "",
      },
      activeMedcert: {},
      activeReport: {},
      batchUploadData: {},
    };
  }

  componentWillMount() {
    if (
      sessionStorage.getItem("loginToken") &&
      sessionStorage.getItem("employeeId")
    ) {
      api
        .post(
          "profiles/fetch_user/",
          {
            employee_id: sessionStorage.getItem("employeeId"),
          },
          {
            headers: {
              Authorization: `${sessionStorage.getItem("loginToken")}`,
            },
          }
        )
        .then(response => {
          console.log({ response });
          sessionStorage.setItem("staffId", response.data.staffId);
          let userResults = response.data.userResults[0];

          // userResults = userResults.reduce((currUser, nextUser) => {
          //   return moment(nextUser.created).diff(currUser.created) > 0
          //     ? currUser
          //     : nextUser;
          // });

          sessionStorage.setItem(
            "staffName",
            `${userResults.firstName} ${userResults.lastName}`
          );
          sessionStorage.setItem("sample", JSON.stringify(userResults));
          this.setUserDetails(userResults);
        })
        .catch(error => {
          console.log(error);
        });
    }
  }

  componentDidUpdate() {
    if (
      sessionStorage.getItem("loginToken") &&
      sessionStorage.getItem("employeeId") &&
      !this.state.userDetails
    ) {
      api
        .post(
          "profiles/fetch_user/",
          {
            employee_id: sessionStorage.getItem("employeeId"),
          },
          {
            headers: {
              Authorization: `${sessionStorage.getItem("loginToken")}`,
            },
          }
        )
        .then(response => {
          sessionStorage.setItem("staffId", response.data.staffId);
          let userResults = response.data.userResults[0];
          sessionStorage.setItem(
            "staffName",
            `${userResults.firstName} ${userResults.lastName}`
          );
          this.setUserDetails(userResults);
        })
        .catch(error => {
          console.log("error");
        });
    }
  }

  setActiveConsultation = consultation => {
    this.setState(previousState => ({ activeConsultation: consultation }));
  };
  setActiveMedcert = medcert =>
    this.setState(previousState => ({ activeMedcert: medcert }));

  setActiveReport = report =>
    this.setState(previousState => ({ activeReport: report }));

  setUserDetails = details =>
    this.setState({
      userDetails: details,
      employeeDetails: details.employee,
      siteDetails: details.employee.site,
    });

  selectEmployee = details => this.setState({ selectedEmployee: details });

  unmountToast = () =>
    this.setState(previousState => ({ isToastActive: false }));

  useToast = (message, type, timeout) => {
    this.setState(previousState => ({
      message: message,
      isToastActive: true,
      toastType: type,
      toastTimeout: timeout,
    }));
  };

  toggleModal = modalName => {
    if (this.state.modalIsOpen)
      this.setState({ modalIsOpen: false, activeModal: "" });
    else this.setState({ modalIsOpen: true, activeModal: modalName });
  };

  useModal = (children, color, heading) => {
    this.setState(previousState => ({
      modal: {
        isOpen: true,
        color: color,
        children: children,
        heading: heading,
      },
    }));
  };

  closeModal = () => {
    this.setState({
      modal: {
        isOpen: false,
        children: null,
        color: null,
        heading: "",
      },
    });
  };

  isModalActive = modalName => {
    return this.state.activeModal === modalName;
  };

  setBatchUploadData = async values => {
    let isSaved;
    await this.setState(
      previousState => ({
        batchUploadData: {
          data: values.data,
          type: values.type,
        },
      }),
      () => {
        isSaved = values.data.length === this.state.batchUploadData.data.length;
      }
    );

    return isSaved;
  };

  render() {
    const { children } = this.props;
    const { employeeStatus } = this.state;

    return (
      <AppContext.Provider
        value={{
          employeeStatus,
          useToast: this.useToast,
          setEmployeeStatus: this.setEmployeeStatus,
          userDetails: this.state.userDetails,
          employeeDetails: this.state.employeeDetails,
          siteDetails: this.state.siteDetails,
          setUserDetails: this.setUserDetails,
          useModal: this.useModal,
          closeModal: this.closeModal,
          activeConsultation: this.state.activeConsultation,
          setActiveConsultation: this.setActiveConsultation,
          activeMedcert: this.state.activeMedcert,
          setActiveMedcert: this.setActiveMedcert,
          activeReport: this.state.activeReport,
          setActiveReport: this.setActiveReport,
          batchUploadData: this.state.batchUploadData,
          setBatchUploadData: this.setBatchUploadData,
        }}
      >
        {children}
        {this.state.isToastActive && (
          <Toast
            message={this.state.message}
            type={this.state.toastType}
            timeout={this.state.toastTimeout}
            unmount={this.unmountToast}
          />
        )}
        {this.state.modal.isOpen && (
          <Modal
            color={this.state.modal.color}
            heading={this.state.modal.heading}
            isOpen={this.state.modal.isOpen}
            close={this.closeModal}
          >
            {this.state.modal.children}
          </Modal>
        )}
      </AppContext.Provider>
    );
  }
}

export default AppContext;

export { AppProvider };
