module.exports = [{
      plugin: require('/tmp/build_4b6239ac/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-109856397-10"},
    },{
      plugin: require('/tmp/build_4b6239ac/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/tmp/build_4b6239ac/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
