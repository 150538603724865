import React from "react";
import classNames from "classnames";

import styles from "./templates.module.scss";

const ModalContent = ({ heading, children, color, close }) => {
  return (
    <div className="box">
      <button className="delete is-pulled-right" onClick={close} />
      <p
        className={classNames(
          `title pb-1 is-size-4 has-text-${color} is-uppercase`,
          styles.customHR
        )}
      >
        {heading}
      </p>
      {children}
    </div>
  );
};

const Modal = ({ heading, color, children, isOpen, close }) => {
  return (
    <div
      className={classNames("modal", isOpen ? "is-active" : null)}
      onKeyDown={event => {
        if (event.key === "Escape") close();
      }}
    >
      <div className="modal-background" />
      <div className="modal-content">
        <ModalContent heading={heading} color={color} close={close}>
          {children}
        </ModalContent>
      </div>
    </div>
  );
};

export default Modal;
